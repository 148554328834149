import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import * as Sentry from '@sentry/nextjs';

import * as UserService from 'Services/UserService';

import { PAYPAL } from 'constants/paymentMethods';

import { trackEvent, trackOrderSuccess } from 'dux/tracking/actions';
import { shouldShowPaypal } from 'dux/featureFlags/selectors';
import { getUserPhone } from 'dux/user/selectors';
import { orderLoadSuccessFromPaymentSuccess } from 'dux/checkoutOrder/thunks';
import { payWithPayPalButton } from 'dux/checkoutPayment/thunks';

import { useCheckoutCategory } from './useCheckoutCategory';

// Documentation: https://developer.paypal.com/braintree/docs/guides/paypal/checkout-with-paypal/javascript/v3/
const renderPayPalPaymentButton = ({
  checkoutCategory,
  currency,
  dispatch,
  navigate,
  phone,
  shippingAddress,
  totalAmount,
  token,
}) => {
  window.braintree?.client?.create(
    {
      authorization: token,
    },
    (clientError, clientInstance) => {
      if (clientError) {
        console.error('PayPal: error creating client:', clientError);
        Sentry.withScope(scope => {
          scope.setLevel('error');
          Sentry.captureMessage(`[PayPal client] error: ${clientError.toString()}`);
        });
        return;
      }
      window.braintree.paypalCheckout?.create(
        {
          client: clientInstance,
        },
        (paypalCheckoutError, paypalCheckoutInstance) => {
          if (paypalCheckoutError) {
            console.error('PayPal: error creating checkout:', paypalCheckoutError);
            Sentry.withScope(scope => {
              scope.setLevel('error');
              Sentry.captureMessage(`[PayPal checkout] error: ${paypalCheckoutError.toString()}`);
            });
            return;
          }
          paypalCheckoutInstance.loadPayPalSDK(
            {
              currency,
              intent: 'tokenize',
              vault: true,
            },
            () => {
              // Paypal btn must be present on the page (CreateCreditCard) before lauching the script
              const paypalButton = document.getElementById('paypal-button');
              if (!paypalButton) {
                console.error('Missing PayPal button');
                return;
              }

              window.paypal
                ?.Buttons({
                  fundingSource: window.paypal.FUNDING.PAYPAL,

                  createBillingAgreement: () =>
                    paypalCheckoutInstance.createPayment({
                      flow: 'vault',
                      amount: totalAmount,
                      currency,
                      intent: 'tokenize',
                      enableShippingAddress: true,
                      shippingAddressEditable: false,
                      ...(shippingAddress && phone
                        ? {
                            shippingAddressOverride: {
                              recipientName: shippingAddress.name,
                              line1: shippingAddress.address1,
                              line2: shippingAddress.address2,
                              city: shippingAddress.city,
                              countryCode: shippingAddress.country,
                              postalCode: shippingAddress.zipcode,
                              state: shippingAddress.zipcode,
                              phone,
                            },
                          }
                        : {}),
                      requestBillingAgreement: true,
                      billingAgreementDetails: {
                        description: 'Your agreement description',
                      },
                    }),

                  onApprove: data => {
                    paypalCheckoutInstance.tokenizePayment(data, async (error, payload) => {
                      const { payload: res } = await dispatch(
                        payWithPayPalButton({
                          checkoutCategory,
                          error,
                          payload,
                        })
                      );
                      /**
                       * On success, redirection to checkout success page
                       * Otherwise, error shown below PayPal btn in CreateCreditCard component
                       */
                      if (res?.status === 'success' && res?.order?.pubkey) {
                        dispatch(orderLoadSuccessFromPaymentSuccess(res.order));
                        dispatch(trackOrderSuccess(res.order));
                        navigate(`/checkout/success?order-pubkey=${res.order.pubkey}`);
                      }
                    });
                  },

                  onCancel: data => {
                    console.log('PayPal payment cancelled', JSON.stringify(data, 0, 2));
                  },

                  onClick: () => {
                    dispatch(
                      trackEvent('payment_button_click', {
                        payment_button_type: PAYPAL,
                      })
                    );
                  },

                  onError: error => {
                    console.error('PayPal: onError', error);
                    Sentry.withScope(scope => {
                      scope.setLevel('error');
                      Sentry.captureMessage(`[PayPal onError] error: ${error.toString()}`);
                    });
                  },
                })
                .render('#paypal-button')
                .then(() => {
                  dispatch(
                    trackEvent('payment_button_view', {
                      payment_button_type: PAYPAL,
                    })
                  );
                });
            }
          );
        }
      );
    }
  );
};

const usePayPalCheckoutButton = ({ currency, shippingAddress, totalAmount }) => {
  const dispatch = useAppDispatch();
  const phone = useAppSelector(getUserPhone);
  const showPayPal = useAppSelector(shouldShowPaypal);
  const checkoutCategory = useCheckoutCategory();

  const navigate = useNavigate();

  useEffect(() => {
    (async function generatePayPalToken() {
      // Feature driven by feature-flag
      if (!showPayPal) return;
      // Paypal btn must be present on the page (CreateCreditCard) before lauching the script
      const paypalButton = document.getElementById('paypal-button');
      if (!paypalButton) {
        console.error('Missing PayPal button');
        return;
      }

      try {
        // Generate token with Prose API
        const { token } = await UserService.fetchPaymentToken({ payment_mode: 'braintree' });
        if (!currency || !totalAmount || !token) {
          console.error('Missing user info or button to use PayPal');
          return;
        }
        // Render PayPal button with Prose token
        renderPayPalPaymentButton({
          checkoutCategory,
          currency,
          dispatch,
          navigate,
          phone,
          shippingAddress,
          totalAmount,
          token,
        });
      } catch (error) {
        console.error('PayPal: error retrieving Prose API token', error);
        Sentry.withScope(scope => {
          scope.setLevel('error');
          Sentry.captureMessage(`[Paypal Prose API token] error: ${error.toString()}`);
        });
      }
    })();
  }, []);
};

export default usePayPalCheckoutButton;
