import PropTypes from 'prop-types';

import { legacyTheme, styled } from '@prose-ui/legacy';

const RadioButton = styled.button`
  appearance: none;
  position: relative;

  padding-left: 24px;
  border: none;

  background-color: transparent;
  text-align: left;

  opacity: ${props => (props.selected ? 1 : 0.8)};

  cursor: pointer;

  &:before {
    position: absolute;
    top: 0;
    left: 0;

    width: 16px;
    height: 16px;

    border: 1px solid ${legacyTheme.palette.common.grey.dark};
    border-radius: 50%;
    content: '';
  }

  &:after {
    position: ${props => (props.selected ? 'absolute' : 'initial')};
    top: ${props => (props.selected ? '3px' : 'initial')};
    left: ${props => (props.selected ? '3px' : 'initial')};

    width: ${props => (props.selected ? '10px' : 'initial')};
    height: ${props => (props.selected ? '10px' : 'initial')};

    border-radius: ${props => (props.selected ? '50%' : 'initial')};
    background-color: ${props => props.selected && legacyTheme.palette.common.grey.dark};

    content: '';
  }
`;

const AddressRadio = ({ children, onClick, selected, dataTestId }) => (
  <RadioButton
    aria-selected={selected}
    data-testid={dataTestId}
    onClick={onClick}
    selected={selected}
    type="button"
  >
    {children}
  </RadioButton>
);

AddressRadio.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string,
};

AddressRadio.defaultProps = {
  dataTestId: 'shipping-method-radio',
};

export default AddressRadio;
