import * as Auth from 'Services/Auth';

import { type AppStartListening } from 'dux/app/listenerMiddleware';
import feedbackApiSlice from 'dux/feedback/apiSlice';
import giftApiSlice from 'dux/gift/apiSlice';
import { actions as giftActions } from 'dux/gift/slice';
import prescriptionApiSlice from 'dux/prescription/apiSlice';
import { locationChanged } from 'dux/router/slice';
import userApiSlice from 'dux/user/apiSlice';

import { signoutUser, userSignout } from './actions';
import { getIsAuthenticated } from './selectors';

/*
 * NOTE: follows strategy #3 of organizing middleware listners
 * https://redux-toolkit.js.org/api/createListenerMiddleware#organizing-listeners-in-files
 */
export const addAuthListeners = (startAppListening: AppStartListening) => {
  startAppListening({
    predicate: (action, _currentState, previousState) => {
      const authTokens = Auth.getTokens();
      // only run on route change action & user state indicate authenticated
      if (
        // Log-out the user before applying route change to avoid triggering page mount fetches (that will 401)
        locationChanged.match(action) &&
        getIsAuthenticated(previousState)
      ) {
        // tokens are present
        if (authTokens) {
          if (
            // refresh token is out-of-date or corrupted
            Auth.isTokenExpired(authTokens.refreshToken) &&
            // access token is out-of-date or corrupted
            Auth.isTokenExpired(authTokens.accessToken)
          ) {
            return true;
          }
          return false;
        }
        return true;
      }
      return false;
    },
    effect: async (_, { dispatch }) => {
      // Sign-out the user, this will also remove token from local Storage
      dispatch(signoutUser());
    },
  });

  startAppListening({
    actionCreator: userSignout,
    effect: (_action, { dispatch }) => {
      dispatch(giftApiSlice.util.resetApiState());
      dispatch(giftActions.reset());
      dispatch(prescriptionApiSlice.util.resetApiState());
      dispatch(userApiSlice.util.resetApiState());
      dispatch(feedbackApiSlice.util.resetApiState());
    },
  });
};
