import { matchPath, useLocation } from 'react-router-dom';

import { checkoutProductsCategories } from '../constants/checkoutProductsCategories';

type CheckoutProductCategories = (typeof checkoutProductsCategories)[number];

export const useCheckoutCategory = () => {
  const { pathname } = useLocation();
  const matchedParams = matchPath('/checkout/:category/*', pathname)?.params;

  if (!matchedParams?.category || !checkoutProductsCategories.includes(matchedParams.category)) {
    return null;
  }

  return matchedParams.category as CheckoutProductCategories;
};
