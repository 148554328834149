import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from 'Services/customBaseQuery';

type FeedbackStatuses = Array<{
  category: 'haircare' | 'skincare';
  sub_category: 'supplements' | 'topicals';
  feedback_pubkey?: string;
  order_pubkey: string;
  status: 'not_started' | 'created' | 'completed' | 'disabled';
}>;

type Fragrance = {
  excluded: boolean;
  limited: boolean;
  name: string; // Maybe more of a 'slug' than a 'name'.
  rank: number;
  reason_why_slug: null | string;
  recommended: boolean;
  visible: boolean;
};
type Fragrances = Array<Fragrance>;

const feedbackApiSlice = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    feedbackStatusesQuery: build.query<FeedbackStatuses, void>({
      query: () => ({
        url: `v1/account/feedback`,
        endpointName: 'feedback/feedbackStatusesQuery',
      }),
      keepUnusedDataFor: 0, // no caching for now
    }),
    fragrancesUsingFeedbackQuery: build.query<Fragrances, void>({
      query: () => ({
        url: 'v1/account/hair_profile/fragrances?use_feedback=true',
        endpointName: 'feedback/fragrancesUsingFeedbackQuery',
      }),
      transformResponse: (response: { fragrances: Fragrances }) => response.fragrances,
      keepUnusedDataFor: 0, // no caching for now
    }),
  }),
});

export default feedbackApiSlice;

export const { feedbackStatusesQuery, fragrancesUsingFeedbackQuery } = feedbackApiSlice.endpoints;
