import { useState } from 'react';

import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { Modal } from '@prose-ui';
import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import { useTrialOfferPromotionModalContent } from 'hooks/useRedeemCouponForTrialOffer';
import Image from 'next/image';

import { ReactComponent as Cross } from 'assets/images/cross_icon.svg';

import useResponsiveVariant from 'utils/useResponsiveVariant';

import { trackHeapEvent } from 'dux/tracking/actions';
import { getFirstHaircareConsultationQuestionRoute } from 'dux/consultation/selectors';
import {
  getLastHaircareCanceledSubscription,
  getLastSkincareCanceledSubscription,
} from 'dux/subscriptions/selectors';
import {
  getHasActiveHaircareSubscription,
  getHasActiveSkincareSubscription,
  getHasCompletedHaircareConsultation,
  getHasCompletedSkincareConsultation,
  getHasSubscription,
} from 'dux/user/selectors';

import Button from './LegacyButton';
import Spacer from './Spacer';
import Typography from './Typography';
import { Video } from './Video';

const Header = styled.div`
  height: 35px;
  padding: 10px 10px 5px 17px;
  background-color: ${theme.colors.tertiary[200]};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const CloseModalButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  padding: 0px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
`;

const StyledModal = styled(Modal)`
  padding: 0px;
  min-width: 330px;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const BadgeLabel = styled(Typography)`
  font-size: 18px;
  text-align: center;
  padding-top: 5px;
`;

const Badge = styled.div`
  position: absolute;
  top: 27px;
  right: 27px;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 76px;
  height: 76px;
  background-color: ${theme.colors.tertiary[200]};
  border-radius: 76px;
`;

const TextContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 28px;
  padding: 16px;
`;

const HeaderLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`;

const Title = styled(Typography)`
  max-width: 220px;
  font-size: 20px;
`;

const Description = styled(Typography)`
  font-size: 14px;

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 430px;
  }
`;

const Conditions = styled(Typography)`
  font-size: 10px;
  padding: 0px 8px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 280px;
`;

type TrialOfferPromotionModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const TrialOfferPromotionModal = ({ isOpen = false, onClose }: TrialOfferPromotionModalProps) => {
  const { content, variant } = useTrialOfferPromotionModalContent();
  const firstHaircareQuestionRoute = useAppSelector(getFirstHaircareConsultationQuestionRoute);
  const hasCompletedHaircareConsultation = useAppSelector(getHasCompletedHaircareConsultation);
  const hasCompletedSkincareConsultation = useAppSelector(getHasCompletedSkincareConsultation);
  const hasHaircareSubscription = useAppSelector(getHasActiveHaircareSubscription);
  const hasHaircareCanceledSubscription = useAppSelector(getLastHaircareCanceledSubscription);
  const hasSkincareSubscription = useAppSelector(getHasActiveSkincareSubscription);
  const hasSkincareCanceledSubscription = useAppSelector(getLastSkincareCanceledSubscription);
  const hasSubscription = useAppSelector(getHasSubscription);
  const { isTablet } = useResponsiveVariant();

  const hasHaircareSubscriptonInAnyState =
    hasHaircareSubscription || hasHaircareCanceledSubscription;
  const hasSkincareSubscriptonInAnyState =
    hasSkincareSubscription || hasSkincareCanceledSubscription;

  const dispatch = useAppDispatch();

  const getCtaProps = () => {
    const consultationStatusKey =
      (hasSkincareSubscriptonInAnyState && hasCompletedHaircareConsultation) ||
      (hasHaircareSubscriptonInAnyState && hasCompletedSkincareConsultation) ||
      (!hasSubscription && hasCompletedHaircareConsultation)
        ? 'consultationCompleted'
        : 'consultationNotCompleted';
    return {
      content: content?.popUpModal,
      cta: content?.popUpModal.cta[consultationStatusKey],
    };
  };
  const ctaProps = getCtaProps();

  if (!content) return null;

  return (
    <StyledModal
      events={{
        onModalClose: () => dispatch(trackHeapEvent(ctaProps.content.tracking.modalClosing)),
        onModalOpen: () => dispatch(trackHeapEvent(ctaProps.content.tracking.modalOpening)),
        timer: {
          onModalOpenAfterTime: () =>
            dispatch(trackHeapEvent(ctaProps.content.tracking.modalOpening2sec)),
        },
      }}
      hideClose
      isOpen={isOpen}
      onClose={onClose}
      zIndexValue={10000}
    >
      <Header>
        {/* @ts-ignore Legacy Typography  */}
        <HeaderLabel medium variant="mono1">
          {ctaProps.content.headerTitle}
        </HeaderLabel>
        <CloseModalButton
          data-click={ctaProps.content.ctaExit.dataClick}
          data-from={ctaProps.content.ctaExit.dataFrom}
          data-testid="close-modal"
          id="modal-close-button"
          onClick={onClose}
        >
          <Cross height="13" width="13" />
        </CloseModalButton>
      </Header>
      <Content>
        {ctaProps.content.video && (
          <Video
            autoplay
            forcePause={false}
            fullHeight
            fullWidth
            loop
            muted
            posterImage={ctaProps.content.video.posterImage}
            posterImageAlt={ctaProps.content.video.posterImageAlt}
            videoSource={ctaProps.content.video.source}
          />
        )}
        {!ctaProps.content.video && (
          <>
            {ctaProps.content.badge && (
              <Badge>
                {/* @ts-expect-error Legacy Typography  */}
                <BadgeLabel>{ctaProps.content.badge}</BadgeLabel>
              </Badge>
            )}
            <StyledImage
              alt={ctaProps.content.imageAlt ?? 'Trial offer'}
              src={ctaProps.content.image}
            />
          </>
        )}
        <TextContent>
          {/* @ts-expect-error Legacy Typography  */}
          <Title
            align="center"
            data-testid={`modal-trial-offer-title-${variant}`}
            id={isOpen ? 'modal-title' : ''}
            variant="h2"
          >
            {ctaProps.content.title}
          </Title>
          {isTablet ? <Spacer size={20} /> : <Spacer size={30} />}
          {/* @ts-expect-error Legacy Typography  */}
          <Description align="center" id={isOpen ? 'modal-description' : ''} variant="p1">
            {ctaProps.content.description}
          </Description>
          <Spacer size={20} />
          {!hasSubscription ? (
            // @ts-expect-error Legacy Button
            <StyledButton
              data-click={ctaProps.cta.dataClick}
              data-from={ctaProps.cta.dataFrom}
              data-testid={`modal-trial-offer-cta-${variant}`}
              onClick={onClose}
              variant="vert"
            >
              {ctaProps.cta.ctaLabel}
            </StyledButton>
          ) : (
            // @ts-expect-error Legacy Button
            <StyledButton
              Component={Link}
              data-click={ctaProps.cta.dataClick}
              data-from={ctaProps.cta.dataFrom}
              data-testid={`modal-trial-offer-cta-${variant}`}
              onClick={onClose}
              to={ctaProps.cta.linkTo(firstHaircareQuestionRoute)}
              variant="vert"
            >
              {ctaProps.cta.ctaLabel}
            </StyledButton>
          )}
          <Spacer size={20} />
          {/* @ts-expect-error Legacy Typography  */}
          <Conditions
            align="center"
            color="grey"
            id={isOpen ? 'modal-conditions' : ''}
            italic
            variant="p3"
          >
            {ctaProps.content.conditions}
          </Conditions>
        </TextContent>
      </Content>
    </StyledModal>
  );
};

type TrialOfferPromotionModalWithStateLogicProps = {
  isOpen: boolean;
};

export const TrialOfferPromotionModalWithStateLogic = ({
  isOpen,
}: TrialOfferPromotionModalWithStateLogicProps) => {
  const [open, setOpen] = useState(isOpen);
  const onClose = () => setOpen(false);

  return <TrialOfferPromotionModal isOpen={open} onClose={onClose} />;
};
