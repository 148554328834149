import { Navigate, Route, Routes } from 'react-router-dom';

import Helmet from 'Components/Helmet';
import RequireAuth from 'Components/RequireAuth';

import NotFound from 'Scenes/NotFound';

import sceneLoadable from 'utils/createLoadablePage';

import AccessoriesConsultationRedirection from './Scenes/AccessoriesConsultationRedirection';

const HomeLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-home" */ './Scenes/Home')
);
const AccessibilityLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-accessibility" */ './Scenes/Accessibility')
);
const AboutLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-about" */ './Scenes/About')
);
const CareersLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-careers" */ './Scenes/Careers')
);
const ContactLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-contact-v2" */ './Scenes/Contact')
);
const SocialImpactLetterLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-social-impact-letter" */ './Scenes/SocialImpactLetter')
);
const FaqLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-faq-v2" */ './Scenes/Faq/index')
);
const HealthPrivacyNoticeLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-health-privacy-notice" */ './Scenes/HealthPrivacyNotice')
);
const IngredientsLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-glossary" */ './Scenes/Glossary')
);
const IngredientLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-ingredient" */ './Scenes/Ingredient')
);
const PrivacyLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-privacy" */ './Scenes/Privacy')
);
const SitemapLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-sitemap" */ './Scenes/Sitemap')
);
const SubscriptionTermsLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-subscription-terms" */ './Scenes/SubscriptionTerms')
);
const TermsLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-terms" */ './Scenes/Terms')
);
const TermsUserTestingLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-terms-user-testing" */ './Scenes/TermsUserTesting')
);

const SocialImpactLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "page-social-impact" */ './Scenes/SocialImpact/index')
);

const MarketingSmsOptinStandalone = sceneLoadable(() =>
  import(
    /* webpackChunkName: "page-standalone-marketing-sms-optin" */ './Scenes/MarketingSmsOptinStandalone'
  )
);

const InstagramLinkInBioLoadable = sceneLoadable(() =>
  import(/* webpackChunkName: "instagram-link-in-bio" */ './Scenes/InstagramLinkInBio')
);

const MainRoutes = () => (
  <Routes>
    <Route element={<HomeLoadable />} index />
    <Route element={<AboutLoadable />} path="about" />

    <Route element={<ContactLoadable />} path="contact" />
    <Route element={<SocialImpactLetterLoadable />} path="social-impact/letter" />
    <Route element={<FaqLoadable />} path="faq/*" />
    <Route path="ingredients">
      <Route element={<IngredientsLoadable />} index />
      <Route element={<IngredientLoadable />} path=":slug" />
    </Route>
    <Route element={<CareersLoadable />} path="careers" />
    <Route element={<PrivacyLoadable />} path="privacy" />
    <Route element={<HealthPrivacyNoticeLoadable />} path="health-privacy-notice" />
    <Route
      element={
        <RequireAuth>
          <MarketingSmsOptinStandalone />
        </RequireAuth>
      }
      path="marketing-sms"
    />
    <Route element={<SitemapLoadable />} path="sitemap" />
    <Route element={<AccessibilityLoadable />} path="accessibility" />
    <Route element={<Helmet noIndex outlet />} path="terms">
      <Route element={<TermsLoadable />} index />
      <Route element={<SubscriptionTermsLoadable />} path="subscription" />
      <Route element={<TermsUserTestingLoadable />} path="user-testing" />
    </Route>
    {/* TODO: To be removed when every links on emails/ads campaign will be updated */}
    <Route
      element={<Navigate replace to="/sustainability-and-social-impact" />}
      path="/values-and-impact"
    />
    <Route element={<SocialImpactLoadable />} path="sustainability-and-social-impact" />
    <Route element={<InstagramLinkInBioLoadable />} path="instagram" />
    <Route element={<AccessoriesConsultationRedirection />} path="signin-accessories-complete" />
    <Route element={<NotFound />} path="*" />
  </Routes>
);

export default MainRoutes;
